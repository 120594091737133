<div class="m-5 p-5">
  <kendo-listview
    [data]="threads()"
    class=""
    containerClass=""
    (scrollBottom)="loadThreads()"
    [height]="650"
  >
    <ng-template kendoListViewHeaderTemplate>
      <div class="header">Messages</div>
    </ng-template>
    <ng-template
      kendoListViewItemTemplate
      let-dataItem="dataItem"
      let-isFirst="isFirst"
    >
      <app-thread
        class="contact"
        [thread]="dataItem"
        [borderTop]="!isFirst"
      >
      </app-thread>
    </ng-template>
    <ng-template kendoListViewFooterTemplate>
      <div class="footer">{{ count() }} unread messages in total</div>
    </ng-template>
  </kendo-listview>
</div>

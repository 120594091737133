import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { Thread } from '../../services/message-service';
import { SessionService } from 'src/app/core/services/session.service';
import { PhonePipe } from 'src/app/core/pipes/phone.pipe';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thread',
  standalone: true,
  imports: [
    CommonModule,
    ButtonsModule,
    LayoutModule,
    PhonePipe
  ],
  templateUrl: './thread.component.html',
  styleUrls: ['./thread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreadComponent { 
  private session = inject(SessionService);
  public router = inject(Router);

  number = this.session.user?.selectedNumber?.number!;

  @Input() thread: Thread = {
    _id: '',
    conversationId: '',
    from: '',
    to: '',
    occurred_at: new Date(),
    text: ''
  }
  
  @Input() public borderTop = false;

  get avatarUrl() {
    const thread = this.thread;
    let number = thread.from !== this.number ? thread.from : thread.to;    
    return `https://avatar.iran.liara.run/public?name=${number}`;
  }

  goToConveration() {
    this.router.navigate(['/sms/conversation'], { queryParams: { id: this.thread.conversationId } });
  }
}

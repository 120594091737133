import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmsRoutingModule } from './sms-routing.module';
import { ChatModule } from '@progress/kendo-angular-conversational-ui';

@NgModule({
  declarations: [    
  ],
  imports: [
    CommonModule,
    SmsRoutingModule,
    ChatModule
  ]
})
export class SmsModule { }

<div class="m-5">
  <div class="chat-container border me-0">
    <div class="toolbar">
      <button
        (click)="router.navigate(['/sms/messages'])"
        class="btn btn-light-primary fs-7"
      >
        <i class="fa-duotone fa-chevron-left me-3"></i>Back to Messages <i class="fa-duotone fa-comments ms-2"></i>
      </button>
    </div>
    <kendo-chat
      [messages]="messages()"
      [user]="primary"
      (sendMessage)="sendMessage($event)"
    >
    </kendo-chat>
  </div>
</div>

import { Component, OnInit, effect, inject, signal } from '@angular/core';
import { Message, User, SendMessageEvent, ChatModule } from '@progress/kendo-angular-conversational-ui';
import { BaseComponent } from 'src/app/core/common/base.component';
import { ConversationService } from './services/conversation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, filter, map, switchMap } from 'rxjs';
import { SessionService } from 'src/app/core/services/session.service';
import { SseService } from 'src/app/core/services/sse.service';

@Component({
  selector: 'app-conversation',
  standalone: true,
  imports: [ChatModule],
  templateUrl: './conversation.page.html',
  styleUrls: ['./conversation.page.scss'],
})
export class ConversationPage extends BaseComponent implements OnInit {
  private route = inject(ActivatedRoute);
  public router = inject(Router);
  private conversationService = inject(ConversationService);
  private session = inject(SessionService);
  private sse = inject(SseService);

  public primary: User = {
    id: this.session.user?.selectedNumber?.number!,
    avatarUrl: `https://avatar.iran.liara.run/public?name=${this.session.user?.selectedNumber?.number!}`,
  };

  public secondary: User = {
    id: '',
    avatarUrl: `https://avatar.iran.liara.run/public`,
  };

  public messages = signal([] as Message[]);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams
        .pipe(
          filter((params) => params['id']),
          switchMap((params) => {
            const id = params['id'];
            const first = id.split('-')[0];
            const second = id.split('-')[1];
            const secondary = first === this.primary.id ? second : first;
            this.secondary.id = secondary;
            if (id) {
              return this.conversationService.getConversation(id).pipe(
                map((response) => {
                  const messages: Message[] = [];
                  response.value.forEach((conversation) => {
                    messages.push({
                      author: conversation.from === this.primary.id ? this.primary : this.secondary,
                      text: conversation.text || '',
                      timestamp: new Date(conversation.occurredAt!),
                    });
                  });
                  return messages;
                })
              );
            } else {
              this.router.navigate(['/sms/messages']);
              return EMPTY;
            }
          })
        )
        .subscribe((messages: Message[]) => {
          this.messages.set(messages);
        });
        
        this.sse
          .getServerSentEvent()
          .pipe(filter((x) => x.topic === 'sms.inbound'))
          .subscribe({
            next: (event) => {
                const messageEvent = event.message;
                const message = {
                  author: messageEvent.from === this.primary.id ? this.primary : this.secondary,
                  text: messageEvent.text,
                  timestamp: new Date(messageEvent.occurredAt),
                };
                const messages = [...this.messages(), message] as Message[];
                messages.sort((a, b) => a.timestamp!.getTime() - b.timestamp!.getTime());
                this.messages.set(messages);              
            },
          });
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {}

  public sendMessage(messageEvent: SendMessageEvent): void {
    console.log('message', messageEvent);
    console.log('recipient', this.secondary);
    this.conversationService.send(messageEvent, this.secondary.id).subscribe({
      next: (response) => {
        console.log('response', response);
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }
}

<div (click)="goToConveration()"
  class="thread"
  style="cursor: pointer;"
  [class.border-top]="borderTop">
    <kendo-avatar
        [imageSrc]="avatarUrl"
        shape="circle"
        width="50px"
        height="50px"
    >
    </kendo-avatar>
    <div class="thread-details">
        <div class="thread-name">{{ (thread.from !== number ? thread.from : thread.to) | phone }}</div>
        <div class="thread-text">{{ thread.text }}</div>
    </div>
    <div class="thread-date fs-8 align-self-start">
      {{ thread.occurred_at | date: 'short'}}
  </div>
</div>
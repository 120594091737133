import { ChangeDetectionStrategy, Component, OnInit, inject, signal } from '@angular/core';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { ThreadComponent } from './components/thread/thread.component';
import { BaseComponent } from 'src/app/core/common/base.component';
import { MessageService, Thread } from './services/message-service';
import { SessionService } from 'src/app/core/services/session.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-messages',
  standalone: true,
  imports: [
    LayoutModule,
    ListViewModule,
    ThreadComponent
  ],
  templateUrl: './messages.page.html',
  styleUrls: ['./messages.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesComponent extends BaseComponent implements OnInit {
  public threads = signal<Thread[]>([]);
  private messageService = inject(MessageService);
  private session = inject(SessionService);
  public count = signal(0);
  
  private pageSize = 10;
  private currentPage = 0;
  private totalRecords = 0;
  
  constructor() {
    super();
  }
  
  ngOnInit(): void {
    this.loadThreads(true);
  }

  public loadThreads(initialLoad: boolean = false) {
    if (!initialLoad && (this.currentPage + 1) * this.pageSize >= this.totalRecords) {
      return; // No more records to load
    }
    
    if (!initialLoad) {
      this.currentPage++;
    }

    const number = this.session.user?.selectedNumber?.number!;
    const skip = this.currentPage * this.pageSize;
    
    this.messageService.getThreads(number, skip, this.pageSize)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.count.set(response['@odata.count']);
        this.totalRecords = response['@odata.count'];
        
        const newThreads = response.value;
        this.threads.set(initialLoad ? newThreads : [...this.threads(), ...newThreads]);
      });
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { AppSettings } from "src/assets/config/app-settings";

@Injectable({providedIn: 'root'})
export class MessageService {
  private http = inject(HttpClient);
  private settings = inject(AppSettings);

  getThreads(number: string, skip = 0, top = 10) {
    const url = `${this.settings.apiBaseUrl}/api/sms/threads?$top=${top}&$skip=${skip}`;
    return this.http
      .get<ThreadsResponse>(url, {
        params: {
          number: number
        }
      });
  }
}

export interface ThreadsResponse {
  ['@odata.count']: number,
  value: Thread[]
}

export interface Thread {  
  _id: string,
  conversationId: string,
  from: string,
  to: string,
  occurred_at: Date,
  text: string
}



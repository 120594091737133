import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit, inject } from '@angular/core';
import { AppSettings } from 'src/assets/config/app-settings';
import { SessionService } from 'src/app/core/services/session.service';
import { SendMessageEvent } from '@progress/kendo-angular-conversational-ui';
import { BaseComponent } from 'src/app/core/common/base.component';

export interface ConversationResponse {
  ['odata.count']: number;
  value: Message[];
}

export interface Message {
  from?: string;
  to?: string;
  text?: string;
  occurredAt?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class ConversationService extends BaseComponent {
  http = inject(HttpClient);
  settings = inject(AppSettings);
  session = inject(SessionService);

  getConversation(conversationId: string) {
    const url = `${this.settings.apiBaseUrl}/api/sms/conversation`;
    return this.http.get<ConversationResponse>(url, { params: { id: conversationId }});
  }

  send(sendMessageEvent: SendMessageEvent, to: string) {
    return this.http.post<Message>(`${this.settings.apiBaseUrl}/api/sms/send`, {
      text: sendMessageEvent.message.text,
      to: to,
      from: sendMessageEvent.message.author.id,
      userId: this.session.user?.userId,
    });
  }
}
